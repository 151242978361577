import React, { useState, useEffect } from 'react';

function ConsentBanner() {
  const [showBanner, setShowBanner] = useState(true);
  const [consentGiven, setConsentGiven] = useState(false);

  useEffect(() => {
    // Check if consent was previously given
    const savedConsent = localStorage.getItem('adsConsent');
    if (savedConsent === 'true') {
      setShowBanner(false);
      setConsentGiven(true);
    }
  }, []);

  const handleConsent = (consent) => {
    if (consent) {
      // User gave consent
      localStorage.setItem('adsConsent', 'true');
      setConsentGiven(true);
      // Trigger Google's consent update
      window.gtag('consent', 'update', {
        'ad_storage': 'granted',
        'analytics_storage': 'granted'
      });
    } else {
      // User denied consent
      localStorage.setItem('adsConsent', 'false');
      setConsentGiven(true);
      // Trigger Google's consent update
      window.gtag('consent', 'update', {
        'ad_storage': 'denied',
        'analytics_storage': 'denied'
      });
    }
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <div className="consent-banner">
      <div className="consent-content">
        <p>
          We use cookies and similar technologies to provide you with a better experience and to show you relevant ads. 
          By clicking "Accept", you consent to our use of cookies and similar technologies.
        </p>
        <div className="consent-buttons">
          <button 
            className="consent-button accept" 
            onClick={() => handleConsent(true)}
          >
            Accept
          </button>
          <button 
            className="consent-button manage" 
            onClick={() => handleConsent(false)}
          >
            Manage Options
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConsentBanner; 